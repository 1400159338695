import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { baseUrl, IMAGE_URL } from '../../../config/Config';
import { useDispatch, useSelector } from 'react-redux';
import { setDash } from '../../../features/user/userSlice';
import { http } from '../../../services/userService';
import { fetchCourses, setSelCourse } from '../../../features/admin/adminSlice';

export function CourseList() {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editedCourse, setEditedCourse] = useState({});
    const [image, setImage] = useState(null);
    const dispatch = useDispatch();

    const { courses } = useSelector(state => state.admin);

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            // width: '25%',
        },

        {
            name: 'Price',
            selector: row => `₹ ${row.price}`,
            sortable: true,
            // width: '10%',
        },

        // {
        //     name: 'Video',
        //     selector: row => row.videos.length,
        //     sortable: true,
        //     // width: '15%',
        // },
        {
            name: 'Users',
            selector: row => row.userCourses.length,
            sortable: true,
            // width: '15%',
        },
        {
            name: 'Status',
            cell: row => (
                <button
                    style={{ background: row.status ? "green" : "red", color: "white", border: "none" }}
                >
                    {row.status ? "Active" : "Inactive"}
                </button>
            ),
            // width:"10%"
        },
        {
            name: 'Popular',
            cell: row => (
                <button
                    style={{ background: row.isPopular ? "green" : "red", color: "white", border: "none" }}
                >
                    {row.isPopular ? "True" : "False"}
                </button>
            ),
            // width:"10%"
        },
        {
            name: 'Actions',
            cell: row => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        style={{ background: 'none', color: 'black' }}
                        onClick={() => handleViewCourse(row)}>View</Button>
                    <Button
                        style={{ background: 'none', color: 'black' }}
                        onClick={() => handleEditCourse(row)}>Edit</Button>
                    {/* <Button
                        style={{ background: 'none', color: 'black' }}
                        onClick={() => handleVideoView(row)}>Videos</Button> */}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '30%',
        },
    ];

  
    const handleViewCourse = (course) => {
        setSelectedCourse(course);
        setShowViewModal(true);
    };

    const handleEditCourse = (course) => {
        setEditedCourse(course);
        setImage(null); // Reset image state
        setShowEditModal(true);
    };

    const handleVideoView = (course) => {
        dispatch(setSelCourse(course));
        dispatch(setDash('videos'))
    };

    const handleEditSubmit = async () => {
        const formData = new FormData();
        formData.append('title', editedCourse.title);
        formData.append('description', editedCourse.description);
        formData.append('price', editedCourse.price);
        formData.append('status', editedCourse.status);
        formData.append('isPopular', editedCourse.isPopular);
        if (image) {
            formData.append('image', image);
        }

        try {
            await http.put(`course/update-course/${editedCourse._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setShowEditModal(false);
            dispatch(fetchCourses())
        } catch (error) {
            console.error('Error updating course:', error);
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await http.delete(`course/${selectedCourse._id}`);
            setShowDeleteModal(false);
            dispatch(fetchCourses())
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };

 
    return (
        <div>

            <div className="card border-0">
                <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="card-title">Courses</h4>
                    <div>
                        <button style={{ border: 'none', borderRadius: '10px' }}
                            onClick={() => dispatch(setDash('addcourse'))}
                        >New Course</button>

                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={courses}
                    />
                </div>
            </div>

            {/* View Modal */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>View Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedCourse && (
                        <div>
                            <h5>{selectedCourse.title}</h5>
                            <p><strong>Description:</strong> {selectedCourse.description}</p>
                            <p><strong>Price:</strong> ₹ {selectedCourse.price}</p>
                            <p><strong>Video Count:</strong> {selectedCourse.videos.length}</p>
                            {selectedCourse.image && <img src={`${IMAGE_URL}${selectedCourse.image}`} alt="Course" style={{ width: '100%' }} />}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowViewModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formTitle" className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={editedCourse.title}
                                onChange={(e) => setEditedCourse({ ...editedCourse, title: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescription" className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={editedCourse.description}
                                onChange={(e) => setEditedCourse({ ...editedCourse, description: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPrice" className="mb-3">
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                                type="number"
                                value={editedCourse.price}
                                onChange={(e) => setEditedCourse({ ...editedCourse, price: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Active"
                                checked={editedCourse.status}
                                onChange={(e) => setEditedCourse({ ...editedCourse, status: e.target.checked })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Popular"
                                checked={editedCourse.isPopular}
                                onChange={(e) => setEditedCourse({ ...editedCourse, isPopular: e.target.checked })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formImage" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </Form.Group>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleEditSubmit()}>Update Course</Button>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the course: <strong>{selectedCourse?.title}</strong>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
