import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { MdVideoSettings } from "react-icons/md";
import { FaPhotoVideo } from "react-icons/fa";
import { FaUsersViewfinder } from "react-icons/fa6";
import { LuPhoneCall } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.css';
import { setPage } from '../../../features/admin/adminSlice';

const Sidebar = ({ isCollapsed }) => {
  const { page } = useSelector(state => state.admin);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(location.pathname));
  }, [location]);

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-logo">
        <Link to='/' style={{ textDecoration: "none", color: "white" }}>Web</Link>
      </div>
      <ul className="sidebar-nav">
        <li className="sidebar-header">Admin Management</li>
        <li className="sidebar-item">
          <Link to="/admin" className="sidebar-link" style={{ color: page === '/admin' ? 'white' : 'gray' }}>
            <RxDashboard />
            Dashboard
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/users" className="sidebar-link" style={{ color: page === '/admin/users' ? 'white' : 'gray' }}>
            <FiUsers />
            Users
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/purchased-users" className="sidebar-link" style={{ color: page === '/admin/purchased-users' ? 'white' : 'gray' }}>
            <FaUsersViewfinder />
            Purchased users
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/consulting" className="sidebar-link" style={{ color: page === '/admin/consulting' ? 'white' : 'gray' }}>
            <LuPhoneCall />
            Consulting Booking
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/courses" className="sidebar-link" style={{ color: page === '/admin/courses' || page === 'addcourse' || page === 'videos' ? 'white' : 'gray' }}>
            <FaPhotoVideo />
            Course
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/transactions" className="sidebar-link" style={{ color: page === '/admin/transactions' ? 'white' : 'gray' }}>
            <RiSecurePaymentLine />
            Transactions
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/videos" className="sidebar-link" style={{ color: page === '/admin/videos' ? 'white' : 'gray' }}>
            <MdVideoSettings />
            Video upload
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/admin/settings" className="sidebar-link" style={{ color: page === '/admin/settings' ? 'white' : 'gray' }}>
            <MdVideoSettings />
            Settings
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
