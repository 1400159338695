import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Row, Col } from 'react-bootstrap';
import './Course.css';
import { baseUrl, IMAGE_URL } from '../../config/Config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, setIsAuthModal, setIsPayment, setSelectedCourse } from '../../features/user/userSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export function AvailableCourse() {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { isAuth, user, userCourses, userCourseIds, availableCourses } = useSelector(state => state.user);

    const handleBuyClick = (course) => {
        dispatch(setSelectedCourse(course));
    }

    useEffect(() =>{
        dispatch(fetchCourses());
    },[])
    
    return (
        <div className="container mt-4">
            <h4 className="mb-4">Courses</h4>
            <Row>
                {availableCourses.map(course => (
                    <Col md={4} key={course.    id} className="mb-4">
                        <Card className="course-card h-100">
                            {course.image && (
                                <Card.Img
                                    variant="top"
                                    src={`${IMAGE_URL}${course.image}`}
                                    alt={course.title}
                                    className="card-img"
                                />
                            )}
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="card-title">{course.title}</Card.Title>
                                <Card.Text className="card-description flex-grow-1">
                                    <strong>Description:</strong> {course.description}
                                </Card.Text>
                                <Card.Text className="card-price">
                                    <strong>Price:</strong> ₹ {course.price}
                                </Card.Text>
                                <div className="button-group mt-auto feature-box">
                                {userCourseIds.includes(course.id) ? (

                                        <Button
                                            variant="success"
                                           onClick={() => navigate('/purchase-course-view', { state: course })} 
                                            >
                                            View
                                           
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="primary"
                                            onClick={() => handleBuyClick(course)}
                                        >
                                            Buy
                                        
                                        </Button>
                                    )}

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
