import React, { useState } from 'react';
import { Modal, Button, Tabs, Tab, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuth, setIsAuthModal, setIsUserPurchase, setLogUser, setRegUser, setToken, setUser, setUserCourses } from '../../features/user/userSlice';
import { http } from '../../services/userService';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';



function AuthModal() {


  const [key, setKey] = useState('login');

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);


  const { isAuthModal, logUser, regUser } = useSelector(state => state.user);

  const handleUserRegister = async (e) => {
    e.preventDefault();

    try {
      const res = await http.post('users/register', regUser);
      if (res.data?.status && res.data.status === "success") {
        toast.success("Register successful!, login to continue");
        setKey('login')
      }
      else {
        console.log(res);
        toast.error("Failed to Register");
      }
    } catch (error) {
      if (error.response?.data) {
        toast.error(error.response?.data?.message);
      }
      else {
        toast.error("Failed to login, server error!")
      }
    }


  }


  const hanldeUserLogin = async (e) => {
    e.preventDefault();
    if (!logUser.email || !logUser.password) {
      toast.error("Fill email and password");
      return;
    }
    try {
      const res = await http.post('users/login', logUser);
      console.log(res, "auth")

      if (res.data?.status && res.data.status === "success") {
        toast.success("Login successful!");
        const { access_token, user } = res.data;
        dispatch(setToken(access_token));
        dispatch(setUser(user));
        dispatch(setIsAuth(true));
        dispatch(setLogUser({ email: '', password: '' }));
        dispatch(setIsAuthModal(false))
        sessionStorage.setItem('token', access_token);
        if (user.userCourses?.length > 0) {
          dispatch(setUserCourses(user.userCourses));
          // dispatch(setIsUserPurchase(true));
        }
      }
      else {
        console.log(res);
        toast.error("Failed to Login");
      }
    } catch (error) {
      if (error.response?.data) {
        toast.error(error.response?.data?.message);
      }
      else {
        toast.error("Failed to login, server error!")
      }
    }
  };


  return (
    <Modal show={isAuthModal} onHide={() => dispatch(setIsAuthModal(false))} centered>
      <Modal.Header>
        <Modal.Title>{key === 'login' ? 'Login' : 'Register'}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          style={{ filter: 'invert(1)' }}
          aria-label="Close"
          onClick={() => dispatch(setIsAuthModal(false))}
        />
      </Modal.Header>


      <Modal.Body>
        <Tabs
          id="auth-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="login" title="Login">
            <Form onSubmit={(hanldeUserLogin)}>
              <Form.Group className="mb-3" controlId="formLoginEmail" >
                <Form.Label >Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" 
                  value={logUser.email} onChange={(e) => dispatch(setLogUser({ ...logUser, email: e.target.value }))} required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLoginPassword">
                <Form.Label >Password</Form.Label>
                <InputGroup>
                  <Form.Control 
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={logUser.password}
                    onChange={(e) => dispatch(setLogUser({ ...logUser, password: e.target.value }))}
                    required
                  />
                  <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <div className="d-flex justify-content-center align-items-center">
              <Button variant="primary" type="submit" >
                Login
              </Button>
              </div>
          

              <p className='text-center pt-3'>Don't have account?

                <Link className='ps-2' onClick={() => setKey('register')}>Regiser</Link>
              </p>
            </Form>
          </Tab>
          <Tab eventKey="register" title="Register">
            <Form onSubmit={handleUserRegister}>
              <Row >
                <Col lg={6} className="mb-3">
                <Form.Label>Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={regUser.name}
                    onChange={(e) => dispatch(setRegUser({ ...regUser, name: e.target.value }))}
                    required
                  />
                </Col>
                <Col lg={6} className="mb-3">
                <Form.Label>Mobile</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Mobile"
                    value={regUser.mobile}
                    onChange={(e) => dispatch(setRegUser({ ...regUser, mobile: e.target.value }))}
                    required
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6} className="mb-3">
                <Form.Label>Email</Form.Label>

                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={regUser.email}
                    onChange={(e) => dispatch(setRegUser({ ...regUser, email: e.target.value }))}
                    required
                  />
                </Col>
                <Col lg={6} >
                  <Form.Group  controlId="formLoginPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={regUser.password}
                        onChange={(e) => dispatch(setRegUser({ ...regUser, password: e.target.value }))}
                        required
                      />
                      <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                        {showPassword ? <FaEye /> : <FaEyeSlash /> }
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row className="mb-3">
                    <Col>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        value={regUser.confirmPass}
                        onChange={(e) => dispatch(setRegUser({ ...regUser, confirmPass: e.target.value }))}
                        required
                      />
                    </Col>
                  </Row> */}

              <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                {/* <Button
                  variant="secondary"
                  onClick={() => dispatch(setRegUser({ name: '', email: '', password: '', confirmPass: '', mobile: '' }))}
                >
                  Clear
                </Button> */}
                <Button variant="primary" type="submit">
                  Register
                </Button>
              </div>
              <p className="text-center pt-2">
                Do you already have an account? <Link to="#" onClick={() => setKey('login')} className="text-link">Login</Link>
              </p>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>



    </Modal>
  );
}

export default AuthModal;
