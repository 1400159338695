import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from '../../api/adminService';
import { fetchSettings } from '../../../features/admin/adminSlice';
import { toast } from 'react-toastify';

export function SettingContent() {
    const { consultingPrice } = useSelector(state => state.admin);
    const [settings, setSettings] = useState({consultingPrice: consultingPrice });
    const dispatch = useDispatch();

    const handleSave = async () => {
        const res = await updateSettings(settings);
        if(res.status == "success"){
            dispatch(fetchSettings());
            toast.success("Succesfullt saved")
        }
    }
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", maxWidth: "400px" }}>
                <div style={{display:"flex"}}>
                    <label>Consulting Price</label>
                    <input 
                        type='text' 
                        name='consultingPrice'
                        value={settings.consultingPrice} 
                        onChange={(e) => setSettings({ ...settings, consultingPrice: e.target.value })}
                        placeholder="Enter consulting price" 
                        style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
                    />
                </div>
           
            </div>

            <button 
                className='mt-2'
                style={{ padding: "10px 20px", backgroundColor: "blue", color: "white", border: "none", cursor: "pointer" }}
                onClick={handleSave}
                >
                    Save
                </button>


        </div>
    )
}

