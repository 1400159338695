import React from 'react';

export const Contactus = () => {
  return (
        <div class="container mt-5">

    <div class="container mt-5">

    <h2><strong>Contact Us</strong></h2>
    <p class="lead">Last updated on 28-09-2024 12:26:34
</p>

        <div class="row mt-4">
            <div class="col-md-6">
                <h3>Company Information</h3>
                <p><strong>Company Name:</strong> Single To Committed</p>
                <p><strong>Email:</strong> singletocommittedd@gmail.com</p>
                <p><strong>Location:</strong> Madurai</p>

            </div>

        </div>

    </div>

</div>
  );
};

