import React, { useEffect } from 'react'
import { Container, Image, Row, Col, Card, Button } from 'react-bootstrap'
import imgPhone from '../../images/phone_consulting.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, setSelectedCourse } from '../../features/user/userSlice';
import ConsultingStatus from './ConsultingStatus';
import { useNavigate } from 'react-router-dom';

function ConsultingHome() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { consultingPrice } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(fetchCourses());
    }, [])

    const cardData = [
        {
            title: "Step 1 : we listen",
            description: "All People who have interest in Photo & video editing"
        },
        {
            title: "Step 2: solution",
            description: "All People who have interest in Photo & video editing"
        },
        {
            title: "Step 3 : terms & conditions",
            description: "All People who have interest in Photo & video editing"
        },
        {
            title: "Step 4 : don’t waste your money",
            description: "All People who have interest in Photo & video editing"
        },
        {
            title: "Step 5 : guarantee",
            description: "All People who have interest in Photo & video editing"
        }
    ];

    const handleBuyClick = () => {
        dispatch(setSelectedCourse({ title: "1-1 Phone consulation", price: consultingPrice, type: 'consulting' }));
    }

    return (
        <Container fluid>
            <Row >

                <Col lg={6} className='p-4' style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop:"6%" }}>
                    <div style={{display:"flex", flexDirection:"column", gap:"30px"}}>
                        <h3 style={{ fontWeight: "bolder", fontFamily: "monospace" }}>1-1 Phone consulation</h3>
                        <p style={{ fontFamily: "monospace" }}>
                            Relationship Phycology அனைவரும் கட்டாயமாக தெரிந்து கொள்ள வேண்டிய ஒன்று. அது தெரியாமல் இருப்பதால் தான்  பலர் Committed ஆக முடியாமல் Single ஆக இருக்கின்றனர்.மேலும் பலர் Relationship-ல் மகிழ்ச்சி மற்றும் திருப்தி இல்லாமல் இருக்கின்றனர். அதனால் இந்த Relationship phycology-ஐ அனைவருக்கும்  கற்றுக் தந்து உங்கள் காதல் மற்றும் உறவு சார்ந்த பிரச்சனைகளுக்கு முற்றுப்புள்ளி வைக்கவே நாங்கள் எங்களது phone consultation-ஐ வழங்குகிறோம்
                        </p>
                        <div>
                            <Button
                                className='animate__animated animate__pulse animate__infinite feature-box'
                                // onClick={() => handleBuyClick()}
                                onClick={() => navigate("/payment/page")}
                                variant="danger"
                                // size="lg"
                                style={{ padding: "10px 10px", borderRadius: "10px", fontWeight: "600", transition: "background 0.3s, transform 0.3s" }}
                            >
                                BOOK PHONE CONSULTATION
                            </Button>
                        </div>
                    </div>

                </Col>
                <Col lg={6} style={{marginTop:"6%"}}>
                    <div style={{ padding: "0% 3%" }}>
                        <img src={imgPhone} width="100%" height={400} style={{ border: "1px solid black", borderRadius: "10px" }} />
                    </div>
                </Col>
            </Row>
            <Row>

            </Row>
            <Row className="g-4 py-5" style={{ padding: 0, margin: 0 }}>
                {cardData.map((card, index) => (
                    <Col xs={12} sm={6} md={6} lg={4} key={index}>
                        <Card className="text-center card-view animate__animated animate__fadeInUp">
                            {/* <Card.Img variant="top" src={card.img} className="card-image" /> */}
                            <Card.Body>
                                <Card.Title>{card.title}</Card.Title>
                                <Card.Text>{card.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default ConsultingHome