import React from 'react';
import './Home.css'; // Import the CSS file for styling
import Cards from './Cards';
import { Button } from 'react-bootstrap';
import { IoMdLogIn } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { AvailableCourse } from '../course/AvailableCourse';


const HomePage = () => {
  const navigate = useNavigate()
  return (
    <div className="home-page">
      <div className="home-banner">
        {/* Optionally, add some content or animation here */}
      </div>
      <div className="centered-box">
        <Button variant='danger'
        //  onClick={() => {navigate("/popular-course")}}
         >
          Explore New Course <IoMdLogIn width={50}/>

        </Button>
      </div>
      <Cards />
      {/* <AvailableCourse /> */}
    </div>
  );
};

export default HomePage;
