import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';

export function TransactionList() {
    const { transactions } = useSelector(state => state.admin);
    console.log(transactions);

    const [filterPaid, setFilterPaid] = useState(false);
    const [filterName, setFilterName] = useState('');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;

        return {
            date: `${day}-${month}-${year}`,
            time: `${hours}:${minutes} ${ampm}`
        };
    };

    const columns = [
        {
            name: 'Time',
            selector: row => row.createdAt,
            cell: row => {
                const { date, time } = formatDate(row.createdAt);
                return (
                    <div>
                        <span style={{ color: 'blue' }}>{date}</span>{' '}
                        <span style={{ color: 'green' }}>{time}</span>
                    </div>
                );
            },
            width: "20%",
            sortable: true,
        },
      
        {
            name: 'Email',
            selector: row => row.userEmail,
            width: "20%"
        },
        {
            name: 'Course',
            selector: row => row.courseId?.title,
            width: "10%"
        },
        {
            name: 'Price',
            selector: row => row.price,
            width: "10%"
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => (
                <button
                    style={{
                        background: row.status === 'paid' ? 'green' : 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '4px'
                    }}
                >
                    {row.status}
                </button>
            ),
            width: "10%"
        },
        {
            name: 'Message',
            selector: row => row.message,
            width: "20%"
        },
        {
            name: 'Activity',
            selector: row => row.userMsg,
            width: "20%"
        },
    ];

    // Filter transactions based on the filterPaid and filterName states
    const filteredTransactions = transactions.filter(transaction => {
        const userName = transaction.userName || ''; 
        const matchesPaidFilter = !filterPaid || transaction.status === 'paid';
        const matchesNameFilter = userName.toLowerCase().includes(filterName.toLowerCase());
        return matchesPaidFilter && matchesNameFilter;
    });

    return (
        <div className="card border-0">
            <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h5 className="card-title">Transactions</h5>
                <div>
                    <input
                        type='text'
                        placeholder='Filter by name'
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                        style={{ marginRight: '10px' }}
                    />
                    <input
                        type="checkbox"
                        checked={filterPaid}
                        onChange={(e) => setFilterPaid(e.target.checked)}
                    />
                    <label className='ps-2'>Paid Only</label>
                </div>
            </div>
            <div className="card-body">
                <DataTable
                    columns={columns}
                    data={filteredTransactions}
                    style={{scrollbarWidth:"none"}}

                />
            </div>
        </div>
    );
}

