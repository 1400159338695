import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Trailer from '../../images/srcboy.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import {  setSelectedCourse } from '../../features/user/userSlice';
import { IMAGE_URL } from '../../config/Config';

function PopularCourse() {
    const dispatch = useDispatch();
    const { popularCourse } = useSelector(state => state.user);

    const imgSrc = popularCourse?.image ? `${IMAGE_URL}/${popularCourse.image}` : Trailer;



    const handleBuyClick = () => {
        dispatch(setSelectedCourse(popularCourse));
    }

    return (
        <Container className="py-3">
            <Row className="d-flex align-items-center justify-content-center">
            <Col xs={12} md={8} lg={6} className="text-center">

                {/* <div style={{height: "300px", width: "300px",  }}> */}
                    <Image
                        src={imgSrc}
                        alt="Trailer"
                        fluid
                        style={{ width:"600px", height:"250px",  borderRadius: "10px", transition: "transform 0.3s" }}
                        className="mb-3 rounded hover-zoom"
                    />

                {/* </div> */}

                <p className='text-center' >
                    {popularCourse ? popularCourse.description : 'No description available.'}
                </p>
                

                <div className="d-flex flex-column gap-3 align-items-center justify-content-center" >
                  
                    <Button
                        className='my-5 animate__animated animate__pulse animate__infinite feature-box'
                        onClick={() => handleBuyClick()}
                        variant="danger"
                        size="lg"
                        style={{ padding: "10px 20px", borderRadius: "10px", fontWeight: "600", transition: "background 0.3s, transform 0.3s" }}
                    >
                        Buy Now for ₹ {popularCourse && popularCourse.price}
                    </Button>
                </div>
                </Col>

            </Row>

        </Container>
    );
}

export default PopularCourse;
