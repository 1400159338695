import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RxDashboard } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { MdPhonelinkSetup } from "react-icons/md";
import { setDash } from '../../../features/user/userSlice';
import { LuPhoneCall } from "react-icons/lu";
import { FaSignInAlt } from 'react-icons/fa';

function AdminDashboard() {
  const { users, purchasedUsers, totalAmount, consulting } = useSelector(state => state.admin);
  const dispatch = useDispatch();

  const dashCard = [
    { title: "Total Users", value: users.length, bgColor: "#ff6b6b", icons: <FiUsers size={50}/>, key: 'users' },
    { title: "Purchased Users", value: purchasedUsers.length, bgColor: "#4caf50", icons: <MdPhonelinkSetup size={50}/>, key: 'users2' },
    { title: "Total Bookings", value: consulting.length, bgColor: "#2196f3", icons: <LuPhoneCall size={50}/>, key: 'consulting' },
    { title: "Total Transactions", value: `₹ ${totalAmount}`, bgColor: "#673ab7", icons: <RiSecurePaymentLine size={50}/>, key: 'trans' },
  ];

  return (
    <div className="container-fluid">
      <div className="mb-3">
        <h4>Admin Dashboard</h4>
      </div>
      <Row>
        {dashCard.map((list, index) => (
          <Col xs={12} md={6} lg={3} key={index} className="mb-4">
            <Card className="text-center dashboard-card">
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                {list.icons}
                <Card.Title className='mt-3' style={{ fontWeight: "700"}}>{list.value}</Card.Title>
                <Card.Text style={{ fontWeight: "600" }}>
                  {list.title}
                </Card.Text>
                <Button
                  variant="success"
                  onClick={() => dispatch(setDash(list.key))}
                  style={{ transition: "background 0.3s, transform 0.3s", marginTop: 'auto' }}
                >
                  <FaSignInAlt className="me-2" />
                  View
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AdminDashboard;
