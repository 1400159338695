import React, { useEffect, useState } from 'react';
import profile from '../../../images/profile.jpg';
import { Button } from 'react-bootstrap';
import { HiRefresh } from "react-icons/hi";
import './Dashboard.css'; // Custom CSS for styling
import { fetchBooking, fetchCourses, fetchTrans, fetchUsers } from '../../../features/admin/adminSlice';
import { useDispatch } from 'react-redux';

const Navbar = ({ onSidebarToggle }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    fetch();
  },[])

  function fetch(){
    dispatch(fetchBooking());
    dispatch(fetchCourses());
    dispatch(fetchTrans());
    dispatch(fetchUsers());
  }

  return (
    <nav className="navbar navbar-expand px-3 border-bottom shadow-sm">
      <button
        className="btn"
        id="sidebar-toggle"
        type="button"
        onClick={onSidebarToggle}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="navbar-collapse d-flex justify-content-end align-items-center">
        <HiRefresh
          className='me-4 refresh-icon'
          size={20}
          onClick={() => fetch()}
        />
        <div className="nav-item dropdown">
          <a
            href="#"
            data-bs-toggle="dropdown"
            className="nav-icon"
            onClick={toggleDropdown}
          >
            {/* <img
              src={profile}
              className="avatar img-fluid rounded-circle"
              alt="profile"
            /> */}
          </a>
          <div
            className={`dropdown-menu dropdown-menu-end ${dropdownOpen ? 'show' : ''}`}
          >
            <a href="#" className="dropdown-item">Profile</a>
            <a href="#" className="dropdown-item">Setting</a>
            <a href="#" className="dropdown-item">Logout</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
