import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Image, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout, setIsAuthModal } from '../../features/user/userSlice';
import Logo from '../../images/stc_logo.png';
import UserIcon from '../../images/profile.jpg';
import { toast } from 'react-toastify';
import { CiEdit } from "react-icons/ci";


function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(false);
    const [menuActive, setMenuActive] = useState("");

    const { isAuth, user } = useSelector((state) => state.user);

    useEffect(() => {
        setMenuActive(location.pathname);
    }, [location]);

    const toggleNavbar = () => {
        setExpanded(!expanded);
    };

    return (
        <Navbar bg="white" expand="md" expanded={expanded} className="shadow-sm">
            <Container  style={{marginTop:"-20px", marginBottom:"-13px"}}>
                <Navbar.Brand role="button" onClick={() => navigate("/")}>
                    <Image src={Logo} alt="Logo" width={100} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link
                            className={menuActive === "/" ? "active" : ""}
                            onClick={() => {
                                navigate("/");
                                setExpanded(false);
                            }}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            className={menuActive === "/consulting" ? "active" : ""}
                            onClick={() => {
                                navigate("/consulting");
                                setExpanded(false);
                            }}
                        >
                            Phone Consultation
                        </Nav.Link>
                        {/* <Nav.Link
                            className={menuActive === "/popular-course" ? "active" : ""}
                            onClick={() => {
                                navigate("/popular-course", { state: 1 });
                                setExpanded(false);
                            }}
                        >
                            Popular Course
                        </Nav.Link> */}

                        {/* <Nav.Link
                            className={menuActive === "/all-course" ? "active" : ""}
                            onClick={() => {
                                navigate("/all-course");
                                setExpanded(false);
                            }}
                        >
                            Courses
                        </Nav.Link> */}

                        
                        {/* <Nav.Link
                            className={menuActive === "/my-course" ? "active" : ""}
                            onClick={() => {
                                navigate("/my-course");
                                setExpanded(false);
                            }}
                        >
                            My Course
                        </Nav.Link> */}

                      
{/* 
                        <Nav.Link
                            className={menuActive === "/secure/admin" ? "active" : ""}
                            onClick={() => {
                                navigate("/secure/admin");
                                setExpanded(false);
                            }}
                        >
                            Admin
                        </Nav.Link> */}
                    </Nav>
                    <Nav className="ms-auto" style={{ marginRight: "50px" }}>
                        {isAuth ? (
                            <NavDropdown
                                title={<Image src={UserIcon} alt="User" width={30} height={30} className="rounded-circle" />}
                                id="basic-nav-dropdown"
                                alignRight
                            >
                                <NavDropdown.Item onClick={() => navigate("/profile/edit")}>
                                    {user ? user.name : 'Profile'} <CiEdit />
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => {
                                    dispatch(handleLogout());
                                    toast.success("Logout successfully!");
                                    window.location.reload();
                                }}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <>
                              <Nav.Link
                                className="header-login-btn"
                                onClick={() => dispatch(setIsAuthModal(true))}
                            >
                                Signup
                            </Nav.Link>
                           
                            </>
                            
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
