import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import EditUser from '../modal/EditUser';



export function UsersList() {
    const { users } = useSelector(state => state.admin);

    const [search, setSearch] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [user, setUser]  = useState({name: '', email: '', password:'', mobile: ''});

    const handleClick = (user) =>{
        setUser({name: user.name, email: user.email, mobile: user.mobile, password: ''});
        setIsEdit(true);
    }

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: "10%"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "30%"
        },
        {
            name: "Mobile",
            selector: row => row.mobile,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Course Purchased',
            selector: row => row.userCourses.length,
            cell: row => (
                <button
                    style={{ background: row.userCourses.length ? "green" : "red", color: "white", border: "none" }}
                >
                    {row.userCourses.length ? "True" : "False"}
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            sortable: true,
            width: "10%"
        },
        {
            name: "Edit",
            cell: row => (
                <Button variant='' onClick={() => handleClick(row)}>
                    <CiEdit />
                </Button>
            ),
            width: "10%"
        },
    ];

    // Filter users based on the search term
    const filteredUsers = users.filter(user => {
        const searchLower = search.toLowerCase();
        return (
            user.name.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower) ||
            user.mobile.toLowerCase().includes(searchLower)
        );
    });

    return (
        <>


            <div className="card border-0">
                <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="card-title">Users</h4>
                    <div style={{ marginBottom: '20px' }}>
                        <input
                            type="text"
                            placeholder="Search by name, email, or mobile"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={filteredUsers}
                        pagination
                    />
                </div>
            </div>

            <EditUser isEdit={isEdit} setIsEdit={setIsEdit} user={user} setUser={setUser}/>
        </>
    );
};
