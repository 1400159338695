import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { storage } from '../../../firebase';
import { http } from '../../../services/userService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRef } from 'react';
 

function VideoEdit({setIsEdit, videoData, videoEdit}) {


  const { courseOptions } = useSelector(state => state.admin);
  const navigate = useNavigate();

  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const [videoDetails, setVideoDetails] = useState({});

  useEffect(() =>{
    setVideoDetails(videoData)
  },[videoData]);

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

 
  return (
    <Container>
             <Row className="justify-content-md-center">

        <Col>
          <Form>
            {/* Title */}
            <Form.Group as={Row} className="mb-3" controlId="formTitle">
              <Form.Label column sm={2} style={{ paddingRight: '15px' }}>
                Title
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="title"
                  value={videoDetails.title}
                  onChange={(e) => setVideoDetails({ ...videoDetails, title: e.target.value })}
                />
              </Col>
            </Form.Group>

            {/* Description */}
            <Form.Group as={Row} className="mb-3" controlId="formDescription">
              <Form.Label column sm={2} style={{ paddingRight: '15px' }}>
                Description
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={videoDetails.description}
                  onChange={(e) => setVideoDetails({ ...videoDetails, description: e.target.value })}
                />
              </Col>
            </Form.Group>

            {/* Course */}
            <Form.Group as={Row} className="mb-3" controlId="formCourse">
              <Form.Label column sm={2} style={{ paddingRight: '15px' }}>
                Course
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  name="courseId"
                  value={videoDetails.courseId}
                  onChange={(e) => setVideoDetails({ ...videoDetails, courseId: e.target.value })}
                >
                  <option value="">Select a course</option>
                  {courseOptions.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.title}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            {/* Part */}
            <Form.Group as={Row} className="mb-3" controlId="formPart">
              <Form.Label column sm={2} style={{ paddingRight: '15px' }}>
                Part
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="part"
                  value={videoDetails.part}
                  onChange={(e) => setVideoDetails({ ...videoDetails, part: e.target.value })}
                />
              </Col>
            </Form.Group>

            {/* Thumbnail */}
            {/* <Form.Group as={Row} className="mb-3" controlId="formImage">
              <Form.Label column sm={2} style={{ paddingRight: '15px' }}>
                Thumbnail
              </Form.Label>
              <Col sm={10}>
                <Form.Control type="file" onChange={handleImageChange} required />
              </Col>
            </Form.Group> */}

            {/* Upload Button */}
            <Form.Group as={Row} className="mb-3" >
            <Col className='text-start'>
                <Button variant="secondary" onClick={() =>setIsEdit(false) }>
                  Back
                </Button>
              </Col>
              <Col className='text-end'>
                <Button variant="primary" onClick={() => videoEdit(videoDetails)}>
                  Save
                </Button>
              </Col>
            </Form.Group>

            {uploadProgress > 0 && (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="m-4" />
          )}

          </Form>

         
        </Col>
     
      </Row>
      </Container>
  );
}

export default VideoEdit;
