import React from 'react';

const Policy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: [Date]</p>
      <h2>Information Collection</h2>
      <p>We collect personal information such as name, email address, and other details necessary to provide our services.</p>

      <h2>How We Use Information</h2>
      <p>We use the information we collect in various ways, including to provide, operate, and maintain our website, improve our services, and communicate with you.</p>

      <h2>Security</h2>
      <p>We take reasonable steps to protect your information but cannot guarantee its absolute security.</p>

      <h2>Your Rights</h2>
      <p>You have the right to access, update, or delete your personal data.</p>
      
      <p>If you have any questions about our privacy policy, please contact us at [Your Contact Information].</p>
    </div>
  );
};

export default Policy;
