import { load } from '@cashfreepayments/cashfree-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchUserDetails, setIsAuthModal, setIsPayment } from '../../features/user/userSlice';
import { http } from '../../services/userService';
import { CASHFREE_MODE } from '../../config/Config';

export const handlePayment = async (user, dispatch, selectedCourse, setFormData) => {
 
    let cashfree;
    var initializeSDK = async function () {
        cashfree = await load({ mode: CASHFREE_MODE});
    };
    await initializeSDK();
    console.log(CASHFREE_MODE)
    let paymentSessionId = null;
    let paymentOrderId = null;

    const { mobile, name, email } = user;
    const amount = selectedCourse.price;

    try {
        const req = await http.post(`pay/orderCreate`, { mobile, name, email, amount });
        const res = req.data;
        paymentSessionId = res.order?.payment_session_id;
        paymentOrderId = res.order?.order_id;
    } catch (err) {
        console.log("Order API connection error", err);
        setFormData({
            name: '',
            email: '',
            mobile: '',
        });
        toast.error("Someting went wrong, try again!");
    }

    if (paymentSessionId) {
        let checkoutOptions = {
            paymentSessionId,
            redirectTarget: "_modal",
        };
        cashfree.checkout(checkoutOptions).then(async (result) => {
            if (result.paymentDetails) {
                toast.success("Payment updated successfully");
                saveTransactionDetails(user, amount, paymentSessionId, paymentOrderId, result.paymentDetails.paymentMessage, 'paid', selectedCourse, dispatch);
            } else if (result.error) {
                toast.error("Payment Canceled");
                saveTransactionDetails(user, amount, paymentSessionId, paymentOrderId, 'Payment canceled', 'canceled', selectedCourse, dispatch);
            } else if (result.redirect) {
                toast.error("Payment will be redirected");
                saveTransactionDetails(user, amount, paymentSessionId, paymentOrderId, 'Payment redirected', 'unpaid', selectedCourse, dispatch);
            } else {
                console.log("Order creation error");
            }
            setFormData({
                name: '',
                email: '',
                mobile: '',
            });
        });
    }
};

const saveTransactionDetails = async (user, amount, paymentSessionId, paymentOrderId, paymentMessage, paymentStatus, selectedCourse, dispatch) => {
    try {

        const transactionData = {
            userId: user?.id || null,
            userName: user.name,
            userEmail: user.email,
            userMobile: user.mobile,    
            amount,
            paymentSessionId,
            paymentOrderId,
            paymentMessage,
            paymentStatus,
            courseId: selectedCourse.id,
            mode: selectedCourse.type
        };

        const res = await http.post(`pay/storeTransaction`, transactionData);
        console.log(res);
        if(res.data?.status){
            // dispatch(fetchUserDetails());
            if(res.mode = 'consulting'){
                toast.success("Your consulting booking successfully");
                localStorage.setItem('bookingId', res.data?.result.serviceId);
            }
        }
    } catch (err) {
        console.error(`Error saving ${paymentStatus} transaction details:`, err);
    }
};

