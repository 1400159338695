import React, { useEffect, useState } from 'react';
import sampleImg from '../../images/free-images.jpg';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './Course.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase'; 
function VideoPlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [currentVideo, setCurrentVideo] = useState(null);
  const [course, setCourse] = useState({ videos: [] });
  const [videoUrl, setVideoUrl] = useState('');

  const changeVideo = (video) => {
    setCurrentVideo(video);
  };

  useEffect(() => {
    if (state) {
      setCourse(state);
      setCurrentVideo(state?.videos[0]);
    }
    console.log(state, "course");

  }, [state]);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const videoRef = ref(storage, currentVideo.url); 
        const url = await getDownloadURL(videoRef); 
        console.log(url, "fn");
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL:', error);
      }
    };

    if (currentVideo) {
      fetchVideoUrl();
    }
  }, [currentVideo]);

  // useEffect(() => {
  //   // Disable right-click
  //   document.addEventListener('contextmenu', function(e) {
  //     e.preventDefault();
  //   });

  //   // Disable certain keyboard shortcuts
  //   document.addEventListener('keydown', function(e) {
  //     // Block F12
  //     if (e.key === 'F12') {
  //       e.preventDefault();
  //     }
  //     // Block Ctrl+Shift+I (Inspect)
  //     if (e.ctrlKey && e.shiftKey && e.key === 'I') {
  //       e.preventDefault();
  //     }
  //     // Block Ctrl+Shift+J (Console)
  //     if (e.ctrlKey && e.shiftKey && e.key === 'J') {
  //       e.preventDefault();
  //     }
  //     // Block Ctrl+U (View Source)
  //     if (e.ctrlKey && e.key === 'U') {
  //       e.preventDefault();
  //     }
  //   });

  //   // Optionally, implement the DevTools detection code
  //   const devtools = new Function('debugger');
  //   devtools();
  //   setInterval(() => {
  //     devtools();
  //   }, 1000);

  //   return () => {
  //     // Cleanup event listeners when the component unmounts
  //     document.removeEventListener('contextmenu', () => {});
  //     document.removeEventListener('keydown', () => {});
  //   };
  // }, []);

  return (
    <Container fluid >
      <Row style={{padding:"0px 30px"}}>
        <Col lg={12} sm={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 className="my-4">{course.title || 'Course Title'}</h2>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </div>
        </Col>
        <Col lg={8} sm={12}>
          <div className="video-container mb-4" style={{padding:"10px", background:"#e9ecef"}}>
            {currentVideo ? (
              <>
                <video key={videoUrl} className="w-100" controls 
                   controlsList="nodownload"
                   onContextMenu={(e) => e.preventDefault()}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="mt-2">
                  {currentVideo.title} - {currentVideo.description}
                </div>
              </>
            ) : (
              <p>No video selected</p>
            )}
          </div>
        </Col>
        <Col lg={4}  sm={12}>
          <div className="video-list" style={{padding:"10px", background:"#e9ecef"}}>
            {course.videos.map((video, index) => (
              <div
                key={index}
                className={`video-parts ${video === currentVideo ? 'active' : ''}`}
                onClick={() => changeVideo(video)}
                style={{background:"#fff", display:"flex", flexDirection:"row", gap:"20px", 
                  fontWeight:"600", fontFamily:"monospace"
                }}
                
              >
                <div>
                  <img src={sampleImg} width={80} alt="parts" />
                </div>
                <div>
                  {/* <p>{video.}</p> */}
                  <p>{index +1}. {video.title}</p>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default VideoPlay;
