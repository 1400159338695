import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import { http } from '../../services/userService';
import { toast } from 'react-toastify';

function ConsultingStatus() {
  const [bookingId, setBookingId] = useState('');
  const [bookingData, setBookingData] = useState({});
  const [searchStatus, setSearchStatus] = useState(false);

  useEffect(() => {
    let bookingCode = localStorage.getItem('bookingId');
    if (bookingCode) { setBookingId(bookingCode); }
  }, [])


  const handleSearch = async () => {
    try{
      const res = await http.get('course/book-status', { params: { serviceId: bookingId } });
      if (res.data?.status == 'success') {
        setBookingData(res.data.result);
        toast.success(res.data.message);
      } else if (res.data?.status == 'fail') {
        setBookingData({})
        toast.error(res.data.message)
      } else {
        setBookingData({})
        toast.error("Someting Error")
      }
      setSearchStatus(true);
      console.log(res);
    }
    catch{
      console.log("Network Error");
      toast.error("Network Error")

    }
   
  };

  return (
    <div className="mt-4">
      <Card className="p-3 shadow">
        <h4 className='mb-3'>Consulting Booking Status</h4>
        <Form>
          <Row>
            <Col xs={8}>
              <Form.Group controlId="bookingId">
                <Form.Control
                  type="text"
                  placeholder="Enter booking ID"
                  value={bookingId}
                  onChange={(e) => setBookingId(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Button variant="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      <div className="mt-4">
        {searchStatus && (
          <>
            <h5>Booking Details:</h5>
            {Object.keys(bookingData).length > 0 ? (
              <Card className="mt-2 p-3 shadow-sm">
                <p><strong>Booking ID</strong>: {bookingData.serviceId}</p>
                <p><strong>Booking on</strong>: {bookingData.date}</p>
                <p><strong>Updated on</strong>: {bookingData.updated}</p>
                <p><strong>Status</strong>: {bookingData.message}</p>

              </Card>
            ) : (
              <p>No booking found for the entered ID.</p>
            )}
          </>
        )}

      </div>
    </div>
  );
}

export default ConsultingStatus;
