import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userDetails } from '../../services/userService';
import { getCourses } from '../../services/courseService';

export const fetchUserDetails = createAsyncThunk('user/fetchUserDetails', async (_, { rejectWithValue }) => {
    try {
        const res = await userDetails();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchCourses = createAsyncThunk('course/all', async (_, { rejectWithValue }) => {
    try {
        const res = await getCourses();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Slice
const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: '',
        user: null,
        isAuth: false,
        logUser: { email: '', password: '' },
        regUser: { name: '', email: '', password: '', confirmPass: '', mobile: '',  },
        isPayment: false,
        isAuthModal: false,
        selectedCourse: {},
        userCourses: [],
        isUserPurchase: false,
        isAdmin: true,
        userCourseIds: [],
        availableCourses: [],
        popularCourse: {},
        isGuest: false,
        consultingPrice: 150
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuth: (state, action) =>{
            state.isAuth = true;
        },
        setLogUser: (state, action) => {
            state.logUser = action.payload;
        },
        setRegUser: (state, action) => {
            state.regUser = action.payload;
        },
        setIsAuthModal: (state, action) => {
            state.isAuthModal = action.payload;
        },
        setIsUserPurchase: (state, action) => {
            state.isUserPurchase = action.payload;
        },
        setUserCourses: (state, action) => {
            const userCourses = action.payload;
            state.userCourses = userCourses
            state.userCourseIds= userCourses.map(course => course._id)
        },
        setIsPayment: (state, action) => {
            state.isPayment = action.payload;
        },
        setDash: (state, action) => {
            state.dash = action.payload;
        },
        setIsAdmin: (state, action) =>{
            state.isAdmin = action.payload;
        },
        setSelectedCourse: (state, action) => {
            state.selectedCourse = action.payload;
            state.isPayment = true;
            if(state.isAuth && state.user){
                state.isGuest = true;
            }         
        },  
        handleLogout: (state) => {
            state.token = '';
            state.isAuth = false;
            state.user = null;
            sessionStorage.removeItem('token');
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                if(action.payload?.status && action.payload.status === "success"){
                    const userCourses = action.payload.courses;
                    state.user = action.payload.user;
                    state.isAuth = true;
                    state.token = sessionStorage.getItem('token');
                    state.userCourses = action.payload.courses;
                    state.userCourseIds= userCourses.map(course => course.courseId?.id)
                }
               
            })
            .addCase(fetchUserDetails.rejected, (state) => {
                state.token = '';
                state.isAuth = false;
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                if(action.payload?.status && action.payload.status === "success"){
                   const courses = action.payload.course
                   state.availableCourses= courses;
                   state.consultingPrice = action.payload.consultingPrice;
                   state.popularCourse = courses.find(course => course.isPopular === true) || null;
                }
            })
     
            
    },
});

export const {
    setToken,
    setLogUser,
    setRegUser,
    setIsAuthModal,
    setIsPayment, setSelectedCourse,
    handleLogout,
    setDash, setUser, setIsAuth,
    setIsUserPurchase, setUserCourses,
    setIsAdmin
 
} = userSlice.actions;

export default userSlice.reducer;
