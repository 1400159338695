import axios from 'axios';
import { baseUrl } from '../config/Config';

export const http = axios.create({
    baseURL: baseUrl
});

async function loginUser(user) {
    try {
        const res = await http.post('users/login', user);
        return res.data;
    } catch (error) {
        console.log(error);
    }
}

async function createUser(user) {
    try {
        const res = await http.post('users/register', user);
        console.log(res);
        return res.data;
    } catch (error) {
        console.log(error);
    }
}

async function userDetails() {
    let token = sessionStorage.getItem('token') || '';
    try {
        const http1 = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        const res = await http1.get('users/details');
        console.log(res, "token");
        return res.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}


export { loginUser, createUser, userDetails };
