import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar } from 'react-bootstrap';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { storage } from '../../../firebase';
import { http } from '../../../services/userService';
import { useDispatch, useSelector } from 'react-redux';
import sampleImg from '../../../images/free-images.jpg';
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaRegPlayCircle } from "react-icons/fa";
import { fetchCourses } from '../../../features/admin/adminSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import VideoEdit from './VieoEdit';
import VideoPlay from './VideoPlay';

function VideoList() {

    const { courseOptions, courses } = useSelector(state => state.admin);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [videos, setVideos] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [isPlay, setIsPlay] = useState(false);
    const [playVideo, setPlayVideo] = useState(null);


    const dispatch = useDispatch();

    useEffect(() => {
        const selectedCourse = courses.find(course => course._id === selectedCourseId);
        if (selectedCourse) {
            setVideos(selectedCourse.videos);
        } else {
            setVideos([]);
        }
    }, [courses, selectedCourseId])

    const videoDelete = async (videoId) => {
        const res = await http.post('course/delete-video', { videoId });
        console.log(res);
        if (res?.data?.success) {
            dispatch(fetchCourses());
            toast.success(res.data.message)
        }
        else {
            toast.error(res?.data?.message || "video delete failed");
        }
    }

    const videoEdit = async (video) => {
        console.log(video);
        const res = await http.post('course/edit-video', video);
        console.log(res);
        if (res?.data?.success) {
            dispatch(fetchCourses());
            toast.success(res.data.message)
        }
        else {
            toast.error(res?.data?.message || "video edit failed");
        }
    }

    useEffect(() => {
        dispatch(fetchCourses());
    }, [])

    return (
        <Container>
            <div className='d-flex align-items-end justify-content-between p-4'>
                <div>
                    <Link to='/admin/video/add'>Add Vieo</Link>
                </div>
                <select as="select" name="courseId" onChange={(e) => setSelectedCourseId(e.target.value)}>
                    <option value="">Select a Course</option>
                    {courseOptions.map((course) => (
                        <option key={course.id} value={course.id}>
                            {course.title}
                        </option>
                    ))}
                </select>
            </div>

            {videos.length > 0 && (
                <div className='video-component' style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="video-list" style={{ padding: "10px", width: isEdit || isPlay ? "60%" : "100%" }}>
                        {videos.map((video, index) => (
                            <div
                                key={index}
                                className="video-parts"
                                style={{
                                    background: "#fff",
                                    display: "flex",
                                    alignItems: "center", // Align items vertically center
                                    justifyContent: "space-between", // Space between the title and the buttons
                                    gap: "20px",
                                    fontWeight: "600",
                                    fontFamily: "monospace",
                                    padding: "10px", // Add padding for better spacing
                                    marginBottom: "10px", // Add spacing between video rows
                                    borderRadius: "5px", // Optional: rounded corners for better look
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <img src={sampleImg} width={80} alt="parts" />
                                    <p style={{ margin: 0 }}>{index + 1}. {video.title}</p>
                                </div>
                                <div style={{ marginLeft: "auto", display: "flex", gap: "10px" }}>
                                    <Button
                                        style={{ background: 'none', color: 'black', borderRadius:"40%" }}
                                        onClick={() => { setIsPlay(true); setIsEdit(false); setPlayVideo(video) }}>
                                        <FaRegPlayCircle />
                                    </Button>
                                    <Button
                                        style={{ background: 'none', color: 'black', borderRadius:"40%" }}
                                        onClick={() => { setIsEdit(true); setIsPlay(false); setEditData(video) }}>
                                        <CiEdit />
                                    </Button>
                                    <Button
                                        style={{ background: 'none', color: 'black', borderRadius:"40%" }}
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this video?')) {
                                                videoDelete(video._id);
                                            }
                                        }}
                                    >
                                        <MdOutlineDeleteOutline />
                                    </Button>


                                </div>
                            </div>
                        ))}
                    </div>

                    {
                        isEdit && <div
                            className="video-edit"
                            style={{
                                padding: "10px",
                                width: "40%",
                            }}
                        >
                            <VideoEdit setIsEdit={setIsEdit} videoData={editData} videoEdit={videoEdit} />
                        </div>
                    }

                    {
                        isPlay  &&  
                        <div
                            className="video-edit"
                            style={{
                                padding: "10px",
                                width: "40%",
                            }}
                        >
                            <VideoPlay setIsEdit={setIsEdit} playVideo={playVideo}  />
                        </div>

                    }


                </div>
            )}

        </Container>
    );
}

export default VideoList;
