import { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import CourseImg from '../../images/phone_consulting.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPayment } from '../../features/user/userSlice';
import { baseUrl, IMAGE_URL } from '../../config/Config';
import { handlePayment } from './paymentHandler';

function PaymentModal() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
    });
    const [errors, setErrors] = useState({});


    const { isPayment, user, isAuth, selectedCourse } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const imgSrc = selectedCourse.image ? `${IMAGE_URL}${selectedCourse.image}` : CourseImg;

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validate = () => {
        let formErrors = {};

        if (!/^[A-Za-z\s]{4,}$/.test(formData.name)) {
            formErrors.name = 'Name must contain only letters and spaces, at least 4 characters.';
        }

        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            formErrors.email = 'Invalid email format.';
        }

        if (!/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = 'Mobile number must contain exactly 10 digits.';
        }

        return formErrors;
    };

    const handleNext = () => {
        setStep(2);
    };

    const handleBack = () => {
        setStep(1);
    };

    useEffect(() => {
        if (isAuth && user) {
            setFormData({
                name: user.name,
                email: user.email,
                mobile: user.mobile
            })
        }
    }, [user, isAuth]);

    return (
        <Modal show={isPayment} onHide={() => dispatch(setIsPayment(false))}>
            <Modal.Header>
                <Modal.Title>Order Details</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    style={{ filter: 'invert(1)' }}
                    aria-label="Close"
                    onClick={() => dispatch(setIsPayment(false))}
                />
            </Modal.Header>
            <Modal.Body>
                <>
                    <div style={{ padding: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                            <img src={imgSrc} alt="course" width={100} style={{ borderRadius: '10px' }} />
                            <div>
                                <p style={{ margin: '0', fontWeight: 'bold', fontSize: '1.1em' }}>{selectedCourse.title}</p>
                                <p style={{ margin: '0', color: 'blue', fontSize: '1.2em' }}>₹ {selectedCourse.price}</p>
                            </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <p style={{ margin: '0' }}>Course price</p>
                                <p style={{ margin: '0' }}>₹ {selectedCourse.price}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <p style={{ margin: '0' }}>Internet handling fees</p>
                                <p style={{ margin: '0' }}>₹00.00</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <p style={{ margin: '0' }}>You Pay</p>
                                <p style={{ margin: '0' }}>₹ {selectedCourse.price}</p>
                            </div>
                        </div>
                    </div>
                </>
                {step === 1 && (
                    <>

                        <Modal.Footer style={{ border: "none" }}>
                            <Button variant="secondary" onClick={() => dispatch(setIsPayment(false))}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleNext}>
                                Next
                            </Button>
                        </Modal.Footer>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Form style={{ padding: '20px' }}>
                            <Form.Group controlId="formName" style={{ marginBottom: '10px' }} className='row'>
                                <Form.Label className='col-lg-4'>Name: </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleFormChange}
                                    className='col'
                                    isInvalid={!!errors.name}

                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formEmail" style={{ marginBottom: '10px' }} className='row'>
                                <Form.Label className='col-lg-4'>Email: </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleFormChange}
                                    className='col'
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formmobile" style={{ marginBottom: '10px' }} className='row'>
                                <Form.Label className='col-lg-4'>Mobile: </Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleFormChange}
                                    className='col'
                                    isInvalid={!!errors.mobile}
                                />
                                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>

                            </Form.Group>
                        </Form>
                        <Modal.Footer style={{ border: "none" }}>
                            <Button variant="secondary" onClick={handleBack}>
                                Back
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    const validationErrors = validate();
                                    if (Object.keys(validationErrors).length === 0) {
                                         handlePayment(formData, dispatch, selectedCourse, setFormData);
                                         dispatch(setIsPayment(false));
                                      } else {
                                        setErrors(validationErrors);
                                    }
                                }}
                            >
                                Proceed
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default PaymentModal;
