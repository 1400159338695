import React, { useState } from 'react';
import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';
import QrCode from '../../images/qr_code_sample.png';
import ConsultingStatus from './ConsultingStatus';
import { paymentProof } from '../../services/courseService';
import { toast } from 'react-toastify';
import {  useSelector } from 'react-redux';


export function PaymentProcedure() {

    const [paymentData, setPaymentData] = useState({
        email: '',
        mobile: '',
        image: null
    });
    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePreview] = useState('');
    const {consultingPrice} = useSelector(state => state.user);

    const validateForm = () => {
        let formErrors = {};
        const mobileRegex = /^[6-9]\d{9}$/;


        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(paymentData.email)) {
            formErrors.email = 'Invalid email format.';
        }

        if (!paymentData.mobile.trim()) {
            formErrors.mobile = 'Mobile number is required';
        } else if (!mobileRegex.test(paymentData.mobile)) {
            formErrors.mobile = 'Please enter a valid mobile number';
        }

        if (!paymentData.image) {
            formErrors.image = 'Payment proof is required';
        } else if (!['image/jpeg', 'image/png'].includes(paymentData.image.type)) {
            formErrors.image = 'Only JPG/PNG formats are allowed';
        } else if (paymentData.image.size > 2 * 1024 * 1024) {
            formErrors.image = 'File size must be less than 2MB';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            console.error('Validation failed');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('email', paymentData.email);
            formData.append('mobile', paymentData.mobile);
            const fileInput = document.querySelector('input[type="file"]');
            formData.append('image', fileInput.files[0]);

            const res = await paymentProof(formData);
            if (res.status == 'success') {
                toast.success(res.message);
                handleClear();
                localStorage.setItem('bookingId', res.serviceId);

            } else {
                toast.error(res.message);
            }
        } catch (error) {
            toast.error("Something wrong, please try again later!");
            console.error('Error occurred during form submission:', error);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setPaymentData({ ...paymentData, [id]: value });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPaymentData({ ...paymentData, image: file });
            setImagePreview(URL.createObjectURL(file));
        }
    };


    const handleClear = () => {
        setPaymentData({
            email: '',
            mobile: '',
            image: null
        });
        setImagePreview('');
        setErrors({});
    };

    return (
        <Container>
            <div className='text-center mt-3'>
                <h4>Phone Consultation Service</h4>
                <p>Complete your payment using UPI through apps like GPay, PhonePe, or others</p>
                <h4>Rs. {consultingPrice}</h4>
            </div>
            <Row className=''>
                <Col md={6} className="d-flex mt-3">
                    <Card className='shadow flex-fill'>
                        <Card.Body className='text-center'>
                            <Card.Title className='mt-3'>UPI Payment Details</Card.Title>
                            <Card.Img style={{ height: "200px", width: "40%" }} variant="top" src={QrCode} alt="QR Code" />
                            <Card.Text>
                                <strong>UPI ID:</strong> example@upi
                            </Card.Text>
                            <Card.Text>
                                Scan the QR code or use the UPI ID above to make the payment.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6} className="d-flex mt-3">
                    <Card className='shadow flex-fill'>
                        <Card.Body className='p-4'>
                            <Card.Title className='text-center'>Payment Confirmation</Card.Title>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="mobile">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={paymentData.mobile}
                                        onChange={handleChange}
                                        isInvalid={!!errors.mobile}
                                    />
                                    {errors.mobile && <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group controlId="email" className='mt-3'>
                                    <Form.Label>Mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={paymentData.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                    />
                                    {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                                </Form.Group>

                                <Form.Group controlId="image" className='mt-3'>
                                    <Form.Label>Payment Proof</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleImageUpload}
                                        isInvalid={!!errors.image}
                                    />
                                    {errors.image && <Form.Control.Feedback type="invalid">{errors.image}</Form.Control.Feedback>}
                                    {imagePreview && <img src={imagePreview} alt="Preview" className='mt-3' style={{ width: '100px', height: '100px' }} />}
                                </Form.Group>

                                <div className="mt-4" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "20px" }}>
                                    <Button variant="secondary" className="ms-3" onClick={handleClear}>
                                        Clear
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Confirm Payment
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} md={8} sm={12}>
                    <ConsultingStatus />
                </Col>
            </Row>
        </Container>
    );
}
