import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { IMAGE_URL } from '../../config/Config';
import {useNavigate} from 'react-router-dom'

const CourseCard = ({ course }) => {

  const navigate = useNavigate();

  return (
    <Card className="course-card h-100">
      {course.image && (
        <Card.Img
          variant="top"
          src={`${IMAGE_URL}${course.image}`}
          alt={course.title}
          className="card-img"
        />
      )}
      <Card.Body className="d-flex flex-column">
        <Card.Title className="card-title">{course.title}</Card.Title>
        <Card.Text className="card-description flex-grow-1">
          <strong>Description:</strong> {course.description}
        </Card.Text>
       
        <div className="button-group mt-auto feature-box">
          <Button
            variant="primary"
            onClick={() => {
              // navigate('/test', { state: course });
              navigate('/purchase-course-view', { state: course });
            }}
          >
            View
          </Button>

        </div>
      </Card.Body>
    </Card>
  );
};

export default CourseCard;
