import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { updateBookingStatus } from '../../api/adminService';
import { fetchBooking } from '../../../features/admin/adminSlice';
import { toast } from 'react-toastify';



export function ConsultingList() {
  const { consulting, consultingPrice } = useSelector(state => state.admin);

  const [search, setSearch] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState({ name: '', email: '', password: '', mobile: '' });
  const dispatch = useDispatch();

  const [editRowId, setEditRowId] = useState(null);  
  const [newStatus, setNewStatus] = useState({});  

  const handleStatusChange = async () => {
    const res =  await updateBookingStatus(newStatus);
    if(res.status == "success"){
      dispatch(fetchBooking());
      setEditRowId(null);
      toast.success(res.message);
    }else{
      toast.error("Error on status updating");
    }
    console.log(res)
  };

  const columns = [
  
    {
      name: 'ServiceId',
      selector: row => row.serviceId,
      sortable: true,
      width: "15%"
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
      width: "15%"
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: "15%"
    },
    {
      name: "Date",
      selector: row => row.createdAt,
      sortable: true,
      width: "20%"
    },

    {
      name: "Status",
      selector: row => (
        editRowId === row.id ? (
          <select
            value={newStatus.status}
            onChange={(e) =>  setNewStatus({ bookingId: row.id, status: e.target.value })}
            style={{ borderRadius: '5px', padding: '4px' }}
          >
            <option value="1">Booking</option>
            <option value="2">Pending</option>
            <option value="3">Completed</option>
            <option value="4">Reject</option>
          </select>
        ) : (
          row.status == 1 ? (
            <button className='btn btn-info' style={{padding:"5px",  fontSize:"12px"}}>
              Booking
            </button>
          ) : row.status == 2 ? (
            <button className='btn btn-warning' style={{padding:"5px",  fontSize:"12px"}}>
              Pending
            </button>
          ) : row.status == 3 ? (
            <button className='btn btn-success' style={{padding:"5px",  fontSize:"12px"}}>
              Completed
            </button>
          ) : row.status == 4 ? (
            <button className='btn btn-danger' style={{padding:"5px",  fontSize:"12px"}}>
              Reject
            </button>
          ) : null
        )
      ),
      sortable: true,
      width: "15%",
    },

    {
      name: "Action",
      cell: row => (
        <div>
          {editRowId === row.id ? (
            <div style={{display:"flex", gap:"4px"}}>
              <Button
                variant="success"
                style={{padding:"5px",  fontSize:"12px"}}
                onClick={() => handleStatusChange()}
              >
                Save
              </Button>
              <Button
                variant="secondary"
                style={{padding:"5px",  fontSize:"12px"}}
                onClick={() => setEditRowId(null)}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              variant="primary"
              onClick={() => setEditRowId(row.id)} 
              style={{padding:"7px",  fontSize:"12px"}}

            >
              <CiEdit />
            </Button>
          )}
        </div>
      ),
      width: "20%",
    }
    
  ];


  return (
    <>
      <div className="card border-0">
        <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h4 className="card-title">Booking Details</h4>
          <div style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Search by name, email, or mobile"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={consulting}
            pagination
          />
        </div>
      </div>
    </>
  );
};
