import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PopularCourse from './PopularCourse';

function Popular() {

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6} className="text-center">
          <h2 style={{ paddingTop: "20px" }}>
            Learn <span style={{ color: "#b32309" }}>Web Design</span> by Youngesters
          </h2>
      
        </Col>
      </Row>

      <PopularCourse />


    </Container>

  );
}

export default Popular;
