import axios from 'axios';
import { baseUrl } from '../../config/Config';

export const http = axios.create({
    baseURL: baseUrl
});

export const getCourses = async () => {
    try {
        const res = await http.get('course/get-courses');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


export const getUsers = async () => {
    try {
        const res = await http.get('users');
        return res.data;
    } catch (error) {
        console.log(error);
    }
}

export const getTransactions = async () => {
    try {
        const res = await http.get('pay/all-trans');
        return res.data
    } catch (error) {
        console.log(error);
    }
}

export const updateUser = async (userData) => {
    try {
        const res = await http.post('users/editbyadmin', userData); 
        return res.data;
    } catch (error) {
        console.error("Error updating user:", error);
    }
}

export const getBooking = async () => {
    try {
        const res = await http.get('course/consults');
        console.log(res, "consults");
        return res.data
    } catch (error) {
        console.log(error);
    }
}

export const updateBookingStatus = async (data) => {
    try {
        const res = await http.post('course/book-status-update', data); 
        return res.data;
    } catch (error) {
        console.error("Error updating user:", error);
    }
}

export const getSettings = async () => {
    try {
        const res = await http.get('app/settings');
        return res.data
    } catch (error) {
        console.log(error);
    }
}

export const updateSettings = async (data) => {
    try {
        const res = await http.post('app/change-record', data);
        console.log(res); 
        return res.data;
    } catch (error) {
        console.error("Error updating user:", error);
    }
}
