import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Assuming you're using Redux for state management

const ProtectedRoute = ({ element: Element }) => {
  const {isAdmin} = useSelector(state => state.admin);

  return isAdmin ? <Element /> : <Navigate to="/admin/login" />;
};

export default ProtectedRoute;
