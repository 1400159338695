import { Row, Col, Card, Button } from 'react-bootstrap';
import { FaSadTear, FaShoppingCart, FaSignInAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsAuthModal } from '../../features/user/userSlice';

const NoCoursesPurchased = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isAuth } = useSelector(state => state.user);

    return (
        <Row className="d-flex justify-content-center mt-4">
            <Col xs={12} md={6} lg={4}>
                {
                    isAuth ?

                        <Card className="text-center mb-4">
                            <Card.Body>
                                <FaSadTear size={50} className="mb-3" />
                                <Card.Title>No Courses Purchased</Card.Title>
                                <Card.Text>
                                    You don't have any courses yet.
                                </Card.Text>
                                <Button
                                    variant="primary"
                                    onClick={() => navigate('/popular-course')}
                                    style={{ transition: "background 0.3s, transform 0.3s" }}
                                >
                                    <FaShoppingCart className="me-2" />
                                    View Courses
                                </Button>
                            </Card.Body>
                        </Card>
                        :
                        <Card className="text-center">
                            <Card.Body>
                                <FaSignInAlt size={50} className="mb-3" />
                                <Card.Title>Login to View Purchased Courses</Card.Title>
                                <Card.Text>
                                    Please log in to view your purchased courses.
                                </Card.Text>
                                <Button
                                    variant="secondary"
                                    onClick={() => dispatch(setIsAuthModal(true))}
                                    style={{ transition: "background 0.3s, transform 0.3s" }}
                                >
                                    <FaSignInAlt className="me-2" />
                                    Login
                                </Button>
                            </Card.Body>
                        </Card>

                }
            </Col>


        </Row>
    );
};

export default NoCoursesPurchased;
