import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAJmAfqfZOV1YtAnqKMQZNYBCiBLQ8WTMM",
  authDomain: "react-meeting-35818.firebaseapp.com",
  databaseURL: "https://react-meeting-35818-default-rtdb.firebaseio.com",
  projectId: "react-meeting-35818",
  storageBucket: "react-meeting-35818.appspot.com",
  messagingSenderId: "868657568265",
  appId: "1:868657568265:web:54ae75bc213610810d76d8",
  measurementId: "G-G6JS2CD1DP"
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
