import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBooking, getCourses, getSettings, getTransactions, getUsers } from '../../admin/api/adminService';

export const fetchCourses = createAsyncThunk('admin/course', async (_, { rejectWithValue }) => {
    try {
        const res = await getCourses();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchUsers = createAsyncThunk('admin/users', async (_, { rejectWithValue }) => {
    try {
        const res = await getUsers();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchTrans = createAsyncThunk('admin/trans', async (_, { rejectWithValue }) => {
    try {
        const res = await getTransactions();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const fetchBooking = createAsyncThunk('admin/consults', async (_, { rejectWithValue }) => {
    try {
        const res = await getBooking();
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchSettings = createAsyncThunk('admin/settings', async (_, { rejectWithValue }) => {
    try {
        const res = await getSettings();
        console.log(res);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


// Slice
const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        courses: [],
        users: [],
        transactions: [],
        totalAmount: 0,
        selCourse: {},
        isAdmin: true,
        purchasedUsers: [],
        courseOptions: [],
        consulting: [],
        consultingPrice: 100,
        page: 'dashboard'
        
    },
    reducers: {

        setIsUserPurchase: (state, action) => {
            state.courses = action.payload;
        },
        setSelCourse: (state, action) => {
            state.selCourse = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.fulfilled, (state, action) => {
                if (action.payload?.status && action.payload.status === "success") {
                    const courses = action.payload.courses;
                    state.courses = courses;
                    state.courseOptions = courses.map((course) => {
                        return { id: course._id, title: course.title };
                    });
                }
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                if (action.payload?.status && action.payload.status === "success") {
                    const users = action.payload.users;
                    state.users = users;
                    state.purchasedUsers = users.filter(user => user.userCourses?.length > 0);
                }
            })
            .addCase(fetchTrans.fulfilled, (state, action) => {
                if (action.payload?.status && action.payload.status === "success") {
                    state.transactions = action.payload.transactions
                    state.totalAmount = action.payload?.total
                }
            })
            .addCase(fetchBooking.fulfilled, (state, action) => {
                if (action.payload?.status && action.payload.status === "success") {
                    state.consulting = action.payload.consulting;
                }
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                if (action.payload?.status && action.payload.status === "success") {
                    state.consultingPrice = action.payload.setting?.consutingPrice
                }
            })

    },
});

export const {
    setIsUserPurchase, setSelCourse, setIsAdmin, setPage
} = adminSlice.actions;

export default adminSlice.reducer;
