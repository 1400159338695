import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setIsAdmin } from '../../../features/admin/adminSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AdminAuth() {

  const [adminLogin, setAdminLogin] = useState({username: '', password: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hanldeAdminLogin = (e) =>{
    e.preventDefault();
    if(adminLogin.username === '6020' && adminLogin.password === 'adminuser'){
        dispatch(setIsAdmin(true));
        toast.success("Login Success");
        // navigate(-1);
    } else{
      toast.error("Invalid Creditentials")
    }
  }

  return (
    <div className='d-flex justify-content-center mt-3'>
      <Form onSubmit={hanldeAdminLogin}>
        <Form.Group className="mb-3" controlId="formLoginEmail">
          <Form.Label>User Name</Form.Label>
          <Form.Control type="text" placeholder="Enter name"
              value={adminLogin.username} onChange={(e) => setAdminLogin({...adminLogin, username: e.target.value})} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLoginPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" 
             value={adminLogin.password} onChange={(e) => setAdminLogin({...adminLogin, password: e.target.value})} />

        </Form.Group>

        <Button variant="primary" type='submin' className="w-100" >
          Login
        </Button>
      </Form>

    </div>

  );
}

export default AdminAuth;
