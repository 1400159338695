import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

function Footer() {


  return (
    <footer className="bg-dark text-light pt-5 pb-4">
      <Container>
  
        <Row className="mt-4">
          <Col md={12} className="text-center">
            <a href="https://facebook.com" className="text-light mx-2"><FaFacebookF /></a>
            <a href="https://twitter.com" className="text-light mx-2"><FaTwitter /></a>
            <a href="https://instagram.com" className="text-light mx-2"><FaInstagram /></a>
            <a href="https://linkedin.com" className="text-light mx-2"><FaLinkedinIn /></a>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="text-center">
            <p> Launched 2024 | Single to Committed | All rights reserved.</p>
            {/* &copy; */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
