import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './components/header/Footer';
import AuthModal from './components/auth/AuthModal';
import Header from './components/header/Header';
import { ToastContainer } from 'react-toastify';
import { fetchUserDetails } from './features/user/userSlice';
import PaymentModal from './components/course/PaymentModal'
import Home from './components/home/Home';
import PurchasedCourse from './components/mycourse/PurchasedCourse';
import { AvailableCourse } from './components/course/AvailableCourse';
import Popular from './components/course/Popular';
import VideoPlay from './components/mycourse/VideoPlay';
import VideoList from './admin/components/course/VideoList';
import VideoUpload from './admin/components/course/VideoUpload';
import ConsultingHome from './components/consulting/ConsultingHome';
import Terms from './components/policy/Terms';
import Policy from './components/policy/Policy';
import { Contactus } from './components/policy/Contactus';
import { ConsultingList } from './admin/components/consulting/ConsultingList';
import ConsultingStatus from './components/consulting/ConsultingStatus';
import { fetchSettings } from './features/admin/adminSlice';
import { PaymentProcedure } from './components/consulting/PaymentProcedure';
import Sidebar from './admin/components/dashboard/Siderbar';
import AdminDashboard from './admin/components/header/AdminDashboard';
import { UsersList } from './admin/components/user/UsersList';
import { CourseList } from './admin/components/course/CourseList';
import { TransactionList } from './admin/components/transaction/TransactionList';
import { PurchasedUsers } from './admin/components/user/PurchasedUsers';
import { SettingContent } from './admin/components/auth/SettingContent';
import Navbar from './admin/components/dashboard/Navbar';
import AdminAuth from './admin/components/auth/AdminAuth';
import ProtectedRoute from './ProtectedRoute';



function App() {
  const { isAdmin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  useEffect(() => {
    let token = sessionStorage.getItem('token') || '';
    console.log(token);
    if (token) {
      dispatch(fetchUserDetails());
    }

    dispatch(fetchSettings());

  }, [])

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<ConsultingHome />} />
          <Route path="/popular-course" element={<Popular />} />
          <Route path="/all-course" element={<AvailableCourse />} />
          <Route path="/video" element={<Home />} />
          <Route path="/my-course" element={<PurchasedCourse />} />
          <Route path="/purchase-course-view" element={<VideoPlay />} />
          <Route path="/consulting" element={<ConsultingHome />} />
          <Route path="/consult-status" element={<ConsultingStatus />} />
          <Route path='/payment/page' element={<PaymentProcedure />} />

          <Route path='/admin/login' element={<AdminAuth />} />
          <Route path="/admin" element={<ProtectedRoute element={AdminDashboard} />} />
          <Route path="/admin/courses" element={<ProtectedRoute element={CourseList} />} />
          <Route path="/admin/users" element={<ProtectedRoute element={UsersList} />} />
          <Route path="/admin/transactions" element={<ProtectedRoute element={TransactionList} />} />
          <Route path="/admin/purchased-users" element={<ProtectedRoute element={PurchasedUsers} />} />
          <Route path="/admin/consulting" element={<ProtectedRoute element={ConsultingList} />} />
          <Route path="/admin/settings" element={<ProtectedRoute element={SettingContent} />} />
          <Route path="/admin/videos" element={<ProtectedRoute element={VideoList} />} />
          <Route path="/admin/video/add" element={<ProtectedRoute element={VideoUpload} />} />

          <Route path='/contact_us' element={<Contactus />}></Route>
          <Route path='/terms' element={<Terms />}></Route>
          <Route path='/policy' element={<Policy />}></Route>
        </Routes>
      </Layout>
      <PaymentModal />
      <AuthModal />
      <ToastContainer position="bottom-right" autoClose={3000} theme='dark' />
    </Router>
  );
}

const Layout = ({ children }) => {
  const location = useLocation();
  const show = !location.pathname.startsWith('/admin');
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const { isAdmin } = useSelector((state) => state.admin);

  const handleSidebarToggle = () => {
    setSidebarVisible(!isSidebarVisible);
  };


  return (
    <>
      {
        show || !isAdmin ?
          <div>
            <Header />
            <div style={{ minHeight: "100vh" }}>
              {children}
            </div>
            <Footer />
          </div>

          :

          <div style={{ display: 'flex', minHeight: '100vh' }}>
            <div
              style={{
                width: isSidebarVisible ? '20%' : '0%',
                height: '100vh',
                overflow: 'hidden',
                transition: 'width 0.1s ease',
              }}
            >
              {isSidebarVisible && <Sidebar />}
            </div>

            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                transition: 'width 0.5s ease',
                width: isSidebarVisible ? '80%' : '100%',
              }}
            >
              <Navbar onSidebarToggle={handleSidebarToggle} />
              <div style={{ flexGrow: 1, padding: "20px" }}>
                {children}
              </div>
            </div>
          </div>

      }

    </>
  );
};

export default App;
