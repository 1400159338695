import axios from 'axios';
import { baseUrl } from '../config/Config';

const http = axios.create({
    baseURL: baseUrl
});

export const getCourses = async () => {
    try {
        const res = await http.get('course/avail-course');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const paymentProof = async (data) => {
    try {
        const res = await http.post('course/book-with-proof', data);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


