import React, { useState } from 'react'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import { updateUser } from '../../api/adminService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../../features/admin/adminSlice';

function EditUser({isEdit, setIsEdit, user, setUser}) {

    const dispatch = useDispatch();

    const hanldeUpdate = async () =>{
        const res = await updateUser(user);
        if(res.message = "User updated success"){
            toast.success(res.message);
            dispatch(fetchUsers());
            setIsEdit(false);
        }else{
            toast.error("Failure to update");
        }
       
    }

    return (
        <Modal show={isEdit} onHide={() =>setIsEdit(false)} centered>
            <Modal.Header>
                <h5>Edit</h5>
                <button
                    type="button"
                    className="btn-close"
                    style={{ filter: 'invert(1)' }}
                    aria-label="Close"
                    onClick={() =>setIsEdit(false)}
                />
            </Modal.Header>


            <Modal.Body>

                <Form >
                    <Row >
                        <Col lg={6} className="mb-3">
                            <Form.Label>Name</Form.Label>

                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={user.name}
                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                required
                            />
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Form.Label>Mobile</Form.Label>

                            <Form.Control
                                type="text"
                                placeholder="Mobile"
                                value={user.mobile}
                                onChange={(e) => setUser({ ...user, mobile: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col lg={6} className="mb-3">
                            <Form.Label>Email</Form.Label>

                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={user.email}
                                readOnly
                            />
                        </Col>
                        <Col lg={6} >
                            <Form.Group controlId="formLoginPassword">
                                <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        placeholder="Password"
                                        value={user.password}
                                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    />
                                   
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className='text-center'>
                    <Button variant="primary" onClick={() =>hanldeUpdate()} >
                        Update
                    </Button>

                    </div>
                  
                </Form>

            </Modal.Body>



        </Modal>
    )
}

export default EditUser