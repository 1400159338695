import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
// import './Course.css';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../firebase';
function VideoPlay({playVideo}) {

  const [videoUrl, setVideoUrl] = useState('');
  
 useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const videoRef = ref(storage, playVideo.url); 
        const url = await getDownloadURL(videoRef); 
        console.log(url, "fn");
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL:', error);
      }
    };

    if (playVideo) {
      fetchVideoUrl();
    }
  }, [playVideo]);

  return (
    <Container fluid >
        <Col >
          <div className="video-container mb-4" style={{padding:"10px"}}>
            {playVideo ? (
              <>
                <video key={videoUrl} className="w-100" controls 
                   controlsList="nodownload"
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {/* <div className="mt-2">
                  {currentVideo.title} - {currentVideo.description}
                </div> */}
              </>
            ) : (
              <p>No video selected</p>
            )}
          </div>
        </Col>

    </Container>
  );
}

export default VideoPlay;
