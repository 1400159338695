import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CourseCard from './CourseCard';
import { useDispatch, useSelector } from 'react-redux';
import { FaSadTear, FaShoppingCart } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import NoCoursesPurchased from './NoCoursePurchased';
import { IoLogoInstagram } from 'react-icons/io';
import { setIsAuthModal } from '../../features/user/userSlice';

const PurchasedCourse = () => {

  const { userCourses, isAuth, user } = useSelector(state => state.user);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();



 useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const login = queryParams.get('login');

    console.log(login);
    if (login === 'true' && !user) {
      dispatch(setIsAuthModal(true));
    }
  }, [location]);
  
  return (
    <Container>
      {
        userCourses && userCourses?.length > 0 ?
          <Row>
            <h4 className='text-center p-4'>Your Course </h4>
            {userCourses.map(course => (

              <Col key={course.id} xs={12} md={6} lg={4} className="mb-4">
                <CourseCard course={course} />
              </Col>
            ))}
          </Row>
          :
          <NoCoursesPurchased />
         

      }

    </Container>
  );
};

export default PurchasedCourse;
