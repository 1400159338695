import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Img1 from '../../images/benefits.png';
import Img2 from '../../images/recruitment.png';
import Img3 from '../../images/choice.png';
import Img4 from '../../images/layers.png'
import './Cards.css';

function Cards() {
  const cardData = [
    {
      img: Img1,
      title: "Who is this course for?",
      description: "All People who have interest in Photo & video editing"
    },
    // {
    //   img: Img2,
    //   title: "Who is this course for?",
    //   description: "All People who have interest in Photo & video editing"
    // },
    {
      img: Img3,
      title: "Who is this course for?",
      description: "All People who have interest in Photo & video editing"
    },
    {
      img: Img4,
      title: "Who is this course for?",
      description: "All People who have interest in Photo & video editing"
    }
  ];

  return (
    <Container>
      <Row className="g-4 py-5" style={{padding:0, margin:0}}>
        {cardData.map((card, index) => (
          <Col xs={12} sm={6} md={6} lg={4} key={index}>
            <Card className="text-center card-view animate__animated animate__fadeInUp">
              <Card.Img variant="top" src={card.img} className="card-image" />
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Cards;
