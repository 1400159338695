import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';

export function PurchasedUsers() {
    const { purchasedUsers } = useSelector(state => state.admin);

    const [search, setSearch] = useState('');
    console.log(purchasedUsers);

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: "10%"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "20%"
        },
        {
            name: "Mobile",
            selector: row => row.mobile,
            sortable: true,
            width: "20%"
        },
        {
            name: "Course",
            selector: row => row.userCourses.length,
            sortable: true,
            width: "10%"
        },
        {
            name: "Total Amount",
            selector: row => row.userCourses.reduce((total, course) => total + course.price, 0),
            sortable: true,
            width: "20%"
        }

    ];

    // Filter users based on the search term
    const filteredUsers = purchasedUsers.filter(user => {
        const searchLower = search.toLowerCase();
        return (
            user.name.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower) ||
            user.mobile.toLowerCase().includes(searchLower)
        );
    });

    return (
        <>


            <div className="card border-0">
                <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h5 className="card-title">Purchased users</h5>
                    <div style={{ marginBottom: '20px' }}>
                        <input
                            type="text"
                            placeholder="Search by name, email, or mobile"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={filteredUsers}
                        pagination
                    />
                </div>
            </div>
        </>
    );
};
